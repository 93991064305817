import React from 'react'
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from '../layouts/layout-scroll'
import { graphql } from 'gatsby'
import useBuildTime from '../components/hooks/buildTime'

const NowPage = ({data}) => {
  const isChangelog = data.page.frontmatter.tags && data.page.frontmatter.tags[0] === "changelog" 
  const time = useBuildTime()
  return (
    <Layout>
      <Helmet htmlAttributes={{ class:'content-page' }} />
      <SEO title={data.page.frontmatter.title} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
        className="fade-in"
          >
          <h1>{data.page.frontmatter.title}</h1>
          {isChangelog 
            ? <p>Last built on: {time}</p> 
            : <p>{data.page.frontmatter.subtitle}</p> 
          }
          <div dangerouslySetInnerHTML={{__html: data.page.html}}  />
          <hr className="divider" style={{margin:'2.5rem auto'}}/>
          <p style={{margin: 0}}>Updated {data.page.frontmatter.date}, from <a href={data.page.frontmatter.link} >{data.page.frontmatter.location}</a></p>
      </div>
    </Layout>
  )
}

export default NowPage

export const query = graphql`
  query ContentQuery($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        location
        link
        tags
      }
    }
  }
`
